import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Checkbox } from 'antd';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { LegalContext } from '../../contexts/LegalContext';
import { defaultFormLayout } from '../../consts/formStyles';
import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';

const { Option } = Select;

const StyledReactQuill = styled(ReactQuill)`
  .ql-editor {
    min-height: 200px;
  }
`;

const CreateLegal = (props) => {
  const { createLegal, response, setResponse } = useContext(LegalContext);
  const { modalVisible, setModalVisible, modal } = props;
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(true);
  const [form] = Form.useForm();
  const legalLanguages = [
    {
      name: 'en',
      displayName: 'EN'
    },
    {
      name: 'de',
      displayName: 'DE'
    }
  ];
  const [termValue, setTermValue] = useState('');

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        values.legal.required = required;
        createLegal(values.legal);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'create') {
    return (
      <Modal
        title={`Add new document`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={1000}
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Name"
            name={['legal', 'name']}
            {...ajaxErrorFieldValidationProperties(response, 'name')}
            rules={[
              { required: true, message: 'Please input a document name!' }
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Terms"
            name={['legal', 'terms']}
            {...ajaxErrorFieldValidationProperties(response, 'terms')}
            rules={[
              { required: true, message: 'Please input a terms content!' }
            ]}
          >
            <StyledReactQuill
              theme="snow"
              value={termValue}
              onChange={setTermValue}
            />
          </Form.Item>
          <Form.Item
            label="Language"
            name={['legal', 'language']}
            {...ajaxErrorFieldValidationProperties(response, 'language')}
            rules={[{ required: true, message: 'Please select a language!' }]}
          >
            <Select placeholder="Language">
              {legalLanguages.map((type) => {
                return (
                  <Option key={`${type.name}-type`} value={type.name}>
                    {type.displayName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Required"
            name={['legal', 'required']}
            {...ajaxErrorFieldValidationProperties(response, 'required')}
          >
            <Checkbox
              onChange={(e) => setRequired(e.target.checked)}
              checked={required}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default CreateLegal;
