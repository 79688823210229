import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Button, Divider } from 'antd';
import moment from 'moment';

import { LegalContext } from '../../contexts/LegalContext';
import CreateLegal from './CreateLegal';
import EditLegal from './EditLegal';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import DeleteLegal from './DeleteLegal';
import columnSearch from '../column-search/CustomColumnSearch';
// import DeleteLegal from './DeleteLegal';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const LegalList = () => {
  const { legal, loading } = useContext(LegalContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [selectedDoc, setSelectedDoc] = useState({});

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('name')
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Original ID',
      dataIndex: 'original_id',
      sorter: (a, b) => a.original_id - b.original_id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Language',
      dataIndex: 'language',
      sorter: (a, b) => a.language.localeCompare(b.language),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sorter: (a, b) => moment(a.updated_at) - moment(b.updated_at),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (updated_at) => {
        return <span>{moment(updated_at).format('YYYY-MM-DD HH:mm:ss')}</span>;
      }
    },
    {
      title: 'Action',
      render: (doc) => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('edit');
              setSelectedDoc(doc);
            }}
          >
            <EditOutlined />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('delete');
              setSelectedDoc(doc);
            }}
          >
            <DeleteOutlined />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        />
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={(doc) => doc.id}
        dataSource={legal}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
        loading={loading}
      />
      <CreateLegal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <EditLegal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedDoc={selectedDoc}
      />
      <DeleteLegal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedDoc={selectedDoc}
      />
    </Fragment>
  );
};

export default LegalList;
