import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Divider, Button } from 'antd';
import { Link } from 'react-router-dom';
import { UsersContext } from '../../contexts/UsersContext';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import ChangeUserGroup from './ChangeUserGroup';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  UserOutlined
} from '@ant-design/icons';
import columnSearch from '../column-search/CustomColumnSearch';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const UsersList = () => {
  const { transformedUsers, getUserGroups, loading } = useContext(UsersContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');

  console.log(transformedUsers);

  const columns = [
    {
      title: 'Contact',
      dataIndex: 'contact',
      sorter: (a, b) => a.contact.localeCompare(b.contact),
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('contact')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('status')
    },
    {
      title: 'Type',
      dataIndex: 'user_type',
      sorter: (a, b) => a.user_type.localeCompare(b.user_type),
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('user_type')
    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      sorter: (a, b) => a.paid.localeCompare(b.paid),
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('paid')
    },
    {
      title: 'Memberships',
      render: (user) => (
        <Link to={'/memberships/' + user.username}>
          <span>View</span>
        </Link>
      )
    },
    {
      title: 'Action',
      render: (user) => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('group');
              setSelectedUser(user);
              getUserGroups(user);
            }}
          >
            <UserOutlined />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('edit');
              setSelectedUser(user);
            }}
          >
            <EditOutlined />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('delete');
              setSelectedUser(user);
            }}
          >
            <DeleteOutlined />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        />
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={(user) => user.id}
        dataSource={transformedUsers}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
        loading={loading}
      />
      <CreateUser
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <ChangeUserGroup
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedUser={selectedUser}
      />
      <EditUser
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedUser={selectedUser}
      />
      <DeleteUser
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedUser={selectedUser}
      />
    </Fragment>
  );
};

export default UsersList;
