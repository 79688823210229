import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Checkbox } from 'antd';

import { UsersContext } from '../../contexts/UsersContext';
import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';
import { defaultFormLayout } from '../../consts/formStyles';

const EditUser = (props) => {
  const { editUser, response, setResponse } = useContext(UsersContext);
  const { modalVisible, setModalVisible, modal, selectedUser } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [paid, setPaid] = useState(false);

  useEffect(() => {
    if (selectedUser.paid && selectedUser.paid === 'true') {
      setPaid(true);
    } else {
      setPaid(false);
    }
  }, [selectedUser]);

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        values.user.paid = paid ? 'true' : 'false';
        console.log(values);
        editUser(values.user, selectedUser.id);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({
      progress: false
    });
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    //There is a some sort of race condition problem going on with initialValues and resetFields() in antd4
    //I have to use this use effect so I can reset form values to new values when form loads. Otherwise form will retain old data.
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  useEffect(() => {
    if (modal === 'edit') {
      if (response.code)
        response.errors = {
          email: response.code
        };

      if (!response.progress && !response.code) {
        closeModal();
      }

      if (!response.progress) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'edit') {
    return (
      <Modal
        title={`Edit ${selectedUser.username}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Username"
            name={['user', 'username']}
            {...ajaxErrorFieldValidationProperties(response, 'username')}
            initialValue={selectedUser.username}
          >
            <Input placeholder="Username" disabled />
          </Form.Item>
          {selectedUser.email ? (
            <Form.Item
              label="Email"
              name={['user', 'email']}
              {...ajaxErrorFieldValidationProperties(response, 'email')}
              initialValue={selectedUser.email}
            >
              <Input placeholder="Username" disabled />
            </Form.Item>
          ) : (
            <Form.Item
              label="Phone number"
              name={['user', 'phone_number']}
              {...ajaxErrorFieldValidationProperties(response, 'phone_number')}
              initialValue={selectedUser.phone_number}
              rules={[
                {
                  pattern: /^[+]?[0-9]{4,16}$/,
                  message: 'The input is not a valid phone number!'
                },
                {
                  required: true,
                  message: 'Please enter a phone number!'
                }
              ]}
            >
              <Input placeholder="Phone number" disabled />
            </Form.Item>
          )}

          <Form.Item
            label="Street"
            name={['user', 'street']}
            {...ajaxErrorFieldValidationProperties(response, 'street')}
            initialValue={selectedUser.street}
          >
            <Input placeholder="Street" />
          </Form.Item>
          <Form.Item
            label="Zip code"
            name={['user', 'zip']}
            {...ajaxErrorFieldValidationProperties(response, 'zip')}
            initialValue={selectedUser.zip}
          >
            <Input placeholder="Zip code" />
          </Form.Item>
          <Form.Item
            label="City"
            name={['user', 'city']}
            {...ajaxErrorFieldValidationProperties(response, 'city')}
            initialValue={selectedUser.city}
          >
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item
            label="Country"
            name={['user', 'country']}
            {...ajaxErrorFieldValidationProperties(response, 'country')}
            initialValue={selectedUser.country}
          >
            <Input placeholder="Country" />
          </Form.Item>
          <Form.Item
            label="Business name"
            name={['user', 'business_name']}
            {...ajaxErrorFieldValidationProperties(response, 'business_name')}
            initialValue={selectedUser.business_name}
          >
            <Input placeholder="Business name" />
          </Form.Item>
          <Form.Item
            label="Business country"
            name={['user', 'business_country']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'business_country'
            )}
            initialValue={selectedUser.business_country}
          >
            <Input placeholder="Business country" />
          </Form.Item>
          <Form.Item
            label="Business city"
            name={['user', 'business_city']}
            {...ajaxErrorFieldValidationProperties(response, 'business_city')}
            initialValue={selectedUser.business_city}
          >
            <Input placeholder="Business city" />
          </Form.Item>
          <Form.Item
            label="Business Zip code"
            name={['user', 'business_zip']}
            {...ajaxErrorFieldValidationProperties(response, 'business_zip')}
            initialValue={selectedUser.business_zip}
          >
            <Input placeholder="Business Zip code" />
          </Form.Item>
          <Form.Item
            label="Business address"
            name={['user', 'business_address']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'business_address'
            )}
            initialValue={selectedUser.business_address}
          >
            <Input placeholder="Business address" />
          </Form.Item>
          <Form.Item
            label="Business VAT"
            name={['user', 'business_vat']}
            {...ajaxErrorFieldValidationProperties(response, 'business_vat')}
            initialValue={selectedUser.business_vat}
          >
            <Input placeholder="Business VAT" />
          </Form.Item>
          <Form.Item
            label="Paid"
            name={['user', 'paid']}
            {...ajaxErrorFieldValidationProperties(response, 'paid')}
          >
            <Checkbox
              onChange={(e) => setPaid(e.target.checked)}
              checked={paid}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default EditUser;
