import React, { useContext, useEffect, useState, useRef } from 'react';
import { Modal, Input, Upload, Select } from 'antd';
import { Form } from '@ant-design/compatible';

import { NewsContext } from '../../contexts/NewsContext';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const { TextArea } = Input;

const CreateNewsForm = (props) => {
  const {
    communities,
    createNews,
    response,
    setResponse,
    asyncForEach
  } = useContext(NewsContext);
  const { modalVisible, setModalVisible, modal } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState(undefined);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [gallery, setGallery] = useState([]);
  const [descCharCount, setDescCharCount] = useState(0);
  const [shortDescCharCount, setShortDescCharCount] = useState(0);
  const descInput = useRef(null);
  const shortDescInput = useRef(null);

  const { getFieldDecorator, getFieldError } = props.form;

  const titleError = getFieldError('title') || validationError.title;
  const shortDescriptionError =
    getFieldError('short_description') || validationError.short_description;
  const textError = getFieldError('text') || validationError.text;
  const communitiesError =
    getFieldError('community_ids') || validationError.community_ids;
  const thumbnailError =
    getFieldError('thumbnail') || validationError.thumbnail;
  const galleryError = getFieldError('gallery') || validationError.gallery;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields(async (error, fieldsValue) => {
      if (error) {
        setLoading(false);
        return;
      }

      let medias = [];

      await (async () => {
        await asyncForEach(gallery, async (file) => {
          medias.push(await getBase64(file.originFileObj));
        });
      })();

      await createNews({ ...fieldsValue, thumbnail, medias });
      setThumbnail(undefined);
      setGallery([]);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    setThumbnail(undefined);
    setGallery([]);
    closeModal();
  };

  const handleDescription = (e) => {
    setDescCharCount(e.target.value.length);

    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleShortDescription = (e) => {
    setShortDescCharCount(e.target.value.length);

    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleChange = async (e) => {
    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.thumbnail = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }

    if (e.file) {
      setThumbnail(await getBase64(e.file));
    }
  };

  const handleCommunities = () => {
    const currentValidation = { ...validationError };

    currentValidation.community_ids = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleGalleryCancel = () => setPreviewVisible(false);

  const handleGalleryPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const handleGalleryChange = ({ fileList }) => {
    setGallery([...fileList]);
  };

  useEffect(() => {
    if (modal === 'create') {
      if (response.errors) {
        setValidationError(response.errors);
      } else {
        setValidationError({});
      }

      if (
        response.requestFor === 'create' &&
        response.requestInProgress === false &&
        response.id
      ) {
        closeModal();
      }

      if (
        response.requestFor === 'create' &&
        response.requestInProgress === false
      ) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    setShortDescCharCount(0);
    setDescCharCount(0);
  }, [modalVisible]);

  if (modal === 'create') {
    return (
      <Modal
        title={`Create news`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ maxHeight: '50vh', overflowY: 'scroll' }}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Title"
            validateStatus={titleError ? 'error' : ''}
            help={titleError || ''}
          >
            {getFieldDecorator('title', {
              rules: [
                { required: true, message: 'Please input a news title!' }
              ],
              onChange: (e) => handleChange(e)
            })(<Input name="title" placeholder="Title" />)}
          </Form.Item>

          <Form.Item
            label={`Short description ${shortDescCharCount}/60`}
            validateStatus={shortDescriptionError ? 'error' : ''}
            help={shortDescriptionError || ''}
          >
            {getFieldDecorator('short_description', {
              rules: [
                {
                  required: true,
                  message: 'Please enter a short description!'
                }
              ],
              onChange: (e) => handleShortDescription(e)
            })(
              <TextArea
                ref={shortDescInput}
                maxLength={60}
                autoSize={true}
                placeholder="Short description"
                name="short_description"
              />
            )}
          </Form.Item>

          <Form.Item
            label={`Text ${descCharCount}/∞`}
            validateStatus={textError ? 'error' : ''}
            help={textError || ''}
          >
            {getFieldDecorator('text', {
              rules: [{ required: true, message: 'Please input a news text!' }],
              onChange: (e) => handleDescription(e)
            })(
              <TextArea
                ref={descInput}
                autoSize={true}
                name="text"
                placeholder="Text"
              />
            )}
          </Form.Item>

          <Form.Item
            label="Communities"
            validateStatus={communitiesError ? 'error' : ''}
            help={communitiesError || ''}
          >
            {getFieldDecorator('community_ids', {
              onChange: () => handleCommunities()
            })(
              <Select
                name="community_ids"
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Communities"
              >
                {communities.map((community) => {
                  return (
                    <Option key={community.id} value={community.id}>
                      {community.name}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>

          <Form.Item
            validateStatus={thumbnailError ? 'error' : ''}
            help={thumbnailError || ''}
            label="Thumbnail"
          >
            {getFieldDecorator('thumbnail', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              onChange: (e) => handleChange(e)
            })(
              <Upload
                name="thumbnail"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={() => false}
              >
                {thumbnail ? (
                  <img src={thumbnail} alt="avatar" style={{ width: '100%' }} />
                ) : (
                  <div>
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div className="ant-upload-text">Upload</div>
                  </div>
                )}
              </Upload>
            )}
          </Form.Item>

          <Form.Item
            validateStatus={galleryError ? 'error' : ''}
            help={galleryError || ''}
            label="Gallery"
          >
            <div className="clearfix">
              <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={gallery}
                onPreview={handleGalleryPreview}
                onChange={handleGalleryChange}
              >
                <div>
                  <PlusOutlined />
                  <div className="ant-upload-text">Upload</div>
                </div>
              </Upload>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleGalleryCancel}
              >
                <img
                  alt="example"
                  style={{ width: '100%' }}
                  src={previewImage}
                />
              </Modal>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const CreateNews = Form.create()(CreateNewsForm);

export default CreateNews;
