import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Button, Divider } from 'antd';

import { CitiesContext } from '../../contexts/CitiesContext';
import CreateCity from './CreateCity';
import EditCity from './EditCity';
import DeleteCity from './DeleteCity';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import columnSearch from '../column-search/CustomColumnSearch';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const CitiesList = () => {
  const { transformedCities, loading } = useContext(CitiesContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [selectedCity, setSelectedCity] = useState({});

  const cities = transformedCities.map((city) => {
    const cityName = city.name
      .split(' ')
      .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(' ');

    return {
      ...city,
      name: cityName
    };
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('name')
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Postal code',
      dataIndex: 'postal_code'
    },
    {
      title: 'Country',
      dataIndex: ['Country', 'name'],
      sorter: (a, b) => a.country.name.localeCompare(b.country.name),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Action',
      render: (city) => (
        <span>
          <StyledIcon
            onClick={() => {
              setSelectedCity(city);
              setModal('edit');
              setModalVisible(true);
            }}
          >
            <EditOutlined />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setSelectedCity(city);
              setModal('delete');
              setModalVisible(true);
            }}
          >
            <DeleteOutlined />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        />
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={(city) => city.id}
        dataSource={cities}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
        loading={loading}
      />
      <CreateCity
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <EditCity
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCity={selectedCity}
      />
      <DeleteCity
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCity={selectedCity}
      />
    </Fragment>
  );
};

export default CitiesList;
