import React, { useState, useEffect, createContext, useContext } from 'react';

import { APP_NAME } from '../consts/globals';
import { AuthContext } from './AuthContext';

export const AdminContext = createContext();

const AdminContextProvider = (props) => {
  const { auth, getUserDetails, setLoading, signOut } = useContext(AuthContext);
  const [admin, setAdmin] = useState({});
  const [userRole, setUserRole] = useState(null);

  const getAdmin = async () => {
    try {
      const admin = await getUserDetails();

      setAdmin(admin);
      return admin;
    } catch (error) {
      console.log(error);
    }
  };

  const getUserRole = async () => {
    const admin = await getAdmin();

    if (admin) {
      const adminRoles =
        admin.signInUserSession.idToken.payload['cognito:groups'];

      if (!adminRoles) {
        setUserRole(`${APP_NAME}-user`);
      } else {
        const adminGroup = adminRoles.some((role) => {
          return role === `${APP_NAME}-admin`;
        });
        const editorGroup = adminRoles.some((role) => {
          return role === `${APP_NAME}-editor`;
        });
        const userGroup = adminRoles.some((role) => {
          return role === `${APP_NAME}-user`;
        });

        if (adminGroup) {
          setUserRole(`${APP_NAME}-admin`);
        } else if (editorGroup) {
          setUserRole(`${APP_NAME}-editor`);
        } else if (userGroup) {
          setUserRole(`${APP_NAME}-user`);
        }
      }
    }
  };

  useEffect(() => {
    getUserRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if (auth) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, userRole]);

  const handleSignOut = (e) => {
    e.preventDefault();
    setAdmin({});
    setUserRole('');
    signOut();
  };

  return (
    <AdminContext.Provider value={{ admin, userRole, handleSignOut }}>
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminContextProvider;
