import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Select, Spin } from 'antd';

import { AdminQuickscansContext } from '../../contexts/AdminQuickscansContext';
import { quickScanModel } from './models/quickScanFormModel';

const { Option } = Select;

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  },
  labelAlign: 'left',
  colon: false
};

const spinnerContainer = {
  width: '100%',
  textAlign: 'center'
};

const EditQuickscan = (props) => {
  const {
    response,
    setResponse,
    getQuickscan,
    createQuickscan,
    editQuickscan
  } = useContext(AdminQuickscansContext);

  const {
    modalVisible,
    setModalVisible,
    modal,
    selectedQuickscan,
    setSelectedQuickscan
  } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [quickScanAnswers, setQuickScanAnswers] = useState({});
  const formFields = quickScanModel.formFields;

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        if (quickScanAnswers.quickscan_order_id) {
          editQuickscan(
            values.companyQuickScan,
            quickScanAnswers.quickscan_order_id
          ).then(() => {
            setQuickScanAnswers({});
            setSelectedQuickscan({});
            setLoading(false);
          });
        } else {
          const compQuick = values.companyQuickScan;
          compQuick.quickscan_order_id = selectedQuickscan.id;
          compQuick.company_id = selectedQuickscan.company_id;
          createQuickscan(compQuick).then(() => {
            setQuickScanAnswers({});
            setSelectedQuickscan({});
            setLoading(false);
          });
        }
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setQuickScanAnswers({});
    setSelectedQuickscan({});
    form.resetFields();
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    //There is a some sort of race condition problem going on with initialValues and resetFields() in antd4
    //I have to use this use effect so I can reset form values to new values when form loads. Otherwise form will retain old data.
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible, quickScanAnswers]);

  useEffect(() => {
    if (selectedQuickscan.id && modal === 'admin-quick-scan') {
      setLoading(true);
      getQuickscan(selectedQuickscan.id).then((result) => {
        if (result.id) {
          setQuickScanAnswers(result);
        } else {
          setQuickScanAnswers({});
        }
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuickscan]);

  useEffect(() => {
    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'admin-quick-scan') {
    return (
      <Modal
        title={`Edit quick scan ${selectedQuickscan.id}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        width="50%"
      >
        {loading && Object.keys(quickScanAnswers).length === 0 ? (
          <div style={spinnerContainer}>
            <Spin />
          </div>
        ) : (
          <Form {...formLayout} form={form}>
            <Form.Item
              label={formFields.organisation.label}
              name={['companyQuickScan', 'organisation']}
              initialValue={quickScanAnswers.organisation}
            >
              <Select placeholder="Type">
                {formFields.organisation.values.map((value, index) => {
                  return (
                    <Option key={`organisation-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={formFields.financial.label}
              name={['companyQuickScan', 'financial']}
              initialValue={quickScanAnswers.financial}
            >
              <Select placeholder="Type">
                {formFields.financial.values.map((value, index) => {
                  return (
                    <Option key={`financial-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={formFields.system_audit.label}
              name={['companyQuickScan', 'system_audit']}
              initialValue={quickScanAnswers.system_audit}
            >
              <Select placeholder="Type">
                {formFields.system_audit.values.map((value, index) => {
                  return (
                    <Option key={`system_audit-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={formFields.process.label}
              name={['companyQuickScan', 'process']}
              initialValue={quickScanAnswers.process}
            >
              <Select placeholder="Type">
                {formFields.process.values.map((value, index) => {
                  return (
                    <Option key={`process-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={formFields.location.label}
              name={['companyQuickScan', 'location']}
              initialValue={quickScanAnswers.location}
            >
              <Select placeholder="Type">
                {formFields.location.values.map((value, index) => {
                  return (
                    <Option key={`location-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={formFields.capacity_planning.label}
              name={['companyQuickScan', 'capacity_planning']}
              initialValue={quickScanAnswers.capacity_planning}
            >
              <Select placeholder="Type">
                {formFields.capacity_planning.values.map((value, index) => {
                  return (
                    <Option key={`capacity_planning-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={formFields.product_competence.label}
              name={['companyQuickScan', 'product_competence']}
              initialValue={quickScanAnswers.product_competence}
            >
              <Select placeholder="Type">
                {formFields.product_competence.values.map((value, index) => {
                  return (
                    <Option key={`product_competence-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={formFields.development_competence.label}
              name={['companyQuickScan', 'development_competence']}
              initialValue={quickScanAnswers.development_competence}
            >
              <Select placeholder="Type">
                {formFields.development_competence.values.map(
                  (value, index) => {
                    return (
                      <Option
                        key={`development_competence-${index}`}
                        value={index++}
                      >
                        {value}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label={formFields.productivity.label}
              name={['companyQuickScan', 'productivity']}
              initialValue={quickScanAnswers.productivity}
            >
              <Select placeholder="Type">
                {formFields.productivity.values.map((value, index) => {
                  return (
                    <Option key={`productivity-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={formFields.productivity.label}
              name={['companyQuickScan', 'control']}
              initialValue={quickScanAnswers.control}
            >
              <Select placeholder="Type">
                {formFields.control.values.map((value, index) => {
                  return (
                    <Option key={`control-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={formFields.s5.label}
              name={['companyQuickScan', 's5']}
              initialValue={quickScanAnswers.s5}
            >
              <Select placeholder="Type">
                {formFields.s5.values.map((value, index) => {
                  return (
                    <Option key={`s5-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={formFields.visual_management.label}
              name={['companyQuickScan', 'visual_management']}
              initialValue={quickScanAnswers.visual_management}
            >
              <Select placeholder="Type">
                {formFields.visual_management.values.map((value, index) => {
                  return (
                    <Option key={`visual_management-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={formFields.standardized_work.label}
              name={['companyQuickScan', 'standardized_work']}
              initialValue={quickScanAnswers.standardized_work}
            >
              <Select placeholder="Type">
                {formFields.standardized_work.values.map((value, index) => {
                  return (
                    <Option key={`standardized_work-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={formFields.inventory.label}
              name={['companyQuickScan', 'inventory']}
              initialValue={quickScanAnswers.inventory}
            >
              <Select placeholder="Type">
                {formFields.inventory.values.map((value, index) => {
                  return (
                    <Option key={`inventory-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={formFields.tpm.label}
              name={['companyQuickScan', 'tpm']}
              initialValue={quickScanAnswers.tpm}
            >
              <Select placeholder="Type">
                {formFields.tpm.values.map((value, index) => {
                  return (
                    <Option key={`tpm-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={formFields.schedule.label}
              name={['companyQuickScan', 'schedule']}
              initialValue={quickScanAnswers.schedule}
            >
              <Select placeholder="Type">
                {formFields.schedule.values.map((value, index) => {
                  return (
                    <Option key={`schedule-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={formFields.flow.label}
              name={['companyQuickScan', 'flow']}
              initialValue={quickScanAnswers.flow}
            >
              <Select placeholder="Type">
                {formFields.flow.values.map((value, index) => {
                  return (
                    <Option key={`flow-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={formFields.cycle.label}
              name={['companyQuickScan', 'cycle']}
              initialValue={quickScanAnswers.cycle}
            >
              <Select placeholder="Type">
                {formFields.cycle.values.map((value, index) => {
                  return (
                    <Option key={`flow-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={formFields.pull.label}
              name={['companyQuickScan', 'pull']}
              initialValue={quickScanAnswers.pull}
            >
              <Select placeholder="Type">
                {formFields.pull.values.map((value, index) => {
                  return (
                    <Option key={`pull-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={formFields.deviations.label}
              name={['companyQuickScan', 'deviations']}
              initialValue={quickScanAnswers.deviations}
            >
              <Select placeholder="Type">
                {formFields.deviations.values.map((value, index) => {
                  return (
                    <Option key={`deviations-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={formFields.line_stop_concept.label}
              name={['companyQuickScan', 'line_stop_concept']}
              initialValue={quickScanAnswers.line_stop_concept}
            >
              <Select placeholder="Type">
                {formFields.line_stop_concept.values.map((value, index) => {
                  return (
                    <Option key={`line_stop_concept-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={formFields.problem_solving.label}
              name={['companyQuickScan', 'problem_solving']}
              initialValue={quickScanAnswers.problem_solving}
            >
              <Select placeholder="Type">
                {formFields.problem_solving.values.map((value, index) => {
                  return (
                    <Option key={`problem_solving-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={formFields.machine_design.label}
              name={['companyQuickScan', 'machine_design']}
              initialValue={quickScanAnswers.machine_design}
            >
              <Select placeholder="Type">
                {formFields.machine_design.values.map((value, index) => {
                  return (
                    <Option key={`machine_design-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={formFields.factory_design.label}
              name={['companyQuickScan', 'factory_design']}
              initialValue={quickScanAnswers.factory_design}
            >
              <Select placeholder="Type">
                {formFields.factory_design.values.map((value, index) => {
                  return (
                    <Option key={`factory_design-${index}`} value={index++}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  } else {
    return null;
  }
};

export default EditQuickscan;
