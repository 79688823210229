import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input } from 'antd';
import { defaultFormLayout } from '../../consts/formStyles';

import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';
import { CategorySegmentsContext } from '../../contexts/CategorySegmentsContext';

const CreateCategorySegment = (props) => {
  const { createCategorySegment, response, setResponse } = useContext(
    CategorySegmentsContext
  );
  const { modalVisible, setModalVisible, modal, category, getSegments } = props;

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        values.segment.category_id = category;
        createCategorySegment(values.segment);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    console.log(response);
    if (response.segment || response.segment_name) {
      getSegments();
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  if (modal === 'create-segment') {
    return (
      <Modal
        title={`Add new segment`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Segment"
            name={['segment', 'segment']}
            {...ajaxErrorFieldValidationProperties(response, 'segment')}
            rules={[{ required: true, message: 'Please input a segment!' }]}
          >
            <Input placeholder="Segment" />
          </Form.Item>
          <Form.Item
            label="Name"
            name={['segment', 'segment_name']}
            {...ajaxErrorFieldValidationProperties(response, 'segment_name')}
            rules={[
              { required: true, message: 'Please input a segment name!' }
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default CreateCategorySegment;
