import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import { AwsClient } from 'aws4fetch';
import { API_ENDPOINT } from '../consts/globals';

export const AdminQuickscansContext = createContext();

const AdminQuickscansContextProvider = (props) => {
  const { creds } = useContext(AuthContext);
  const [quickscans, setQuickscans] = useState([]);
  const [pages, setPages] = useState(null);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getQuickscans = async (page) => {
    setLoading(true);

    const includes = 'company';

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/quick-scan-order?include=${includes}&page-size=10&page-number=${page}`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setQuickscans(responseJson.data);
      setPages(responseJson.meta);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getQuickscan = async (id) => {
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/admin-quick-scan/${id}`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      return responseJson;
    } catch (error) {
      console.log(error);
    }
  };

  const createQuickscan = async (companyQuickScan) => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/admin-quick-scan`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(companyQuickScan)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editQuickscanOrder = async (quickscan, orderId) => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/quick-scan-order/${orderId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(quickscan)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editQuickscan = async (quickscan, orderId) => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/admin-quick-scan/${orderId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(quickscan)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteQuickscan = async (id) => {
    setResponse({});
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/admin-quick-scan/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getQuickscans();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getQuickscans(1);

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminQuickscansContext.Provider
      value={{
        quickscans,
        pages,
        getQuickscans,
        createQuickscan,
        editQuickscanOrder,
        editQuickscan,
        getQuickscan,
        deleteQuickscan,
        response,
        setResponse,
        loading
      }}
    >
      {props.children}
    </AdminQuickscansContext.Provider>
  );
};

export default AdminQuickscansContextProvider;
