import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { CommunitiesContext } from './CommunitiesContext';
import { API_ENDPOINT } from '../consts/globals';

export const NewsContext = createContext();

const NewsContextProvider = (props) => {
  const { communities, getCommunities } = useContext(CommunitiesContext);
  const { creds } = useContext(AuthContext);
  const [news, setNews] = useState([]);
  const [selectedNews, setSelectedNews] = useState({});
  const [response, setResponse] = useState({});
  const [gallery, setGallery] = useState([]);
  const [thumbnail, setThumbnail] = useState(undefined);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getNews = async () => {
    setResponse({ requestFor: 'get all', requestInProgress: true });

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/news`, {
        signal: signal,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'get all',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      setNews(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleNews = async (id) => {
    setResponse({ requestFor: 'get single', requestInProgress: true });

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/news/${id}?include=communities,medias`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'get single',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      setGallery(
        responseJson.Medias.map((media) => {
          return {
            uid: media.id,
            name: media.title,
            status: 'done',
            url: media.path,
            existing: true
          };
        })
      );

      setThumbnail(responseJson.thumbnail);

      setSelectedNews(responseJson);
    } catch (error) {
      console.log(error);
    }
  };

  const createNews = async (news) => {
    setResponse({ requestFor: 'create', requestInProgress: true });

    try {
      const transformedNews = {
        ...news
      };

      const response = await aws.fetch(`${API_ENDPOINT}/system/news`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(transformedNews)
      });

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'create',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getNews();
    } catch (error) {
      console.log(error);
    }
  };

  const editNews = async (news, id) => {
    setResponse({
      requestFor: 'edit',
      requestInProgress: true
    });

    try {
      const transformedNews = {
        ...news
      };

      const response = await aws.fetch(`${API_ENDPOINT}/system/news/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(transformedNews)
      });

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'edit',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getNews();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteNews = async (id) => {
    setResponse({ requestFor: 'delete', requestInProgress: true });

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/news/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'delete',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getNews();
    } catch (error) {
      console.log(error);
    }
  };

  const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  useEffect(() => {
    getCommunities();
    getNews();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NewsContext.Provider
      value={{
        communities,
        news,
        getSingleNews,
        selectedNews,
        setSelectedNews,
        createNews,
        editNews,
        deleteNews,
        response,
        setResponse,
        asyncForEach,
        gallery,
        setGallery,
        thumbnail,
        setThumbnail
      }}
    >
      {props.children}
    </NewsContext.Provider>
  );
};

export default NewsContextProvider;
