import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Switch } from 'antd';

import { CompaniesContext } from '../../contexts/CompaniesContext';

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 2 },
  },
  labelAlign: "left",
  colon: false
};

const EditCompanyLean = (props) => {
  const {
    response,
    setResponse,
    getLeanAnswers,
    createCompanyLean,
    editCompanyLean
  } = useContext(CompaniesContext);

  const { modalVisible, setModalVisible, modal, selectedCompany } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [leanAnswers, setLeanAnswers] = useState({});

  const closeModal = () => {
    setLeanAnswers({});
    form.resetFields();
    setModalVisible(false);
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        if (leanAnswers.company_id) {
          editCompanyLean(values.companyLean, leanAnswers.company_id);
        } else {
          createCompanyLean({
            ...values.companyLean,
            company_id: selectedCompany.id
          });
        }
        setLeanAnswers(values.companyLean);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    //There is a some sort of race condition problem going on with initialValues and resetFields() in antd4
    //I have to use this use effect so I can reset form values to new values when form loads. Otherwise form will retain old data.
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  useEffect(() => {
    if (selectedCompany.id && modal === 'company-lean')
      getLeanAnswers(selectedCompany.id).then((result) => {
        setLeanAnswers(result ?? { nonEmpty: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'company-lean') {
    return (
      <Modal
        title={`Edit ${selectedCompany.name}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        width="50%"
      >
        {Object.keys(leanAnswers).length !== 0 ? (
          <Form {...formLayout} form={form}>
            <Form.Item
              label="Are ratings for customer satisfaction and product quality displayed?"
              name={['companyLean', 'customer_rating']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.customer_rating}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Is the facility safe, clean, orderly, and well lit? Is the air quality good and noise levels low?"
              name={['companyLean', 'facility_clean']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.facility_clean}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Does a visual labeling system identify and locate inventory, tools, processes, and flow?"
              name={['companyLean', 'visual_labeling']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.visual_labeling}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Does everything have its own place, and is everything stored in its place?"
              name={['companyLean', 'everything_stored']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.everything_stored}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Are up-to-date operational goals and performance measures for those goals prominently posted?"
              name={['companyLean', 'operational_goals']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.operational_goals}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Are production materials brought to and stored at line side - rather than in separate inventory storage areas?"
              name={['companyLean', 'materials_stored']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.materials_stored}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Are work instructions and product quality specifications visible at all work areas?"
              name={['companyLean', 'work_instructions']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.work_instructions}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Are updated charts on productivity, quality, safety, and problem solving visible for all teams?"
              name={['companyLean', 'updated_charts']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.updated_charts}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Can the current state of the operation be viewed from a central control room, on a status board, or on a Chart?"
              name={['companyLean', 'current_state']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.current_state}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Are production lines scheduled off a single pacing process with appropriate inventory levels at each stage?"
              name={['companyLean', 'production_lines']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.production_lines}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Is material moved only once as short a distance as possible and in appropriate containers?"
              name={['companyLean', 'material_moved']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.material_moved}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label='Is the plant laid out in continuous product flow lines rather than in"shops"?'
              name={['companyLean', 'continuous_flow']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.continuous_flow}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Are work teams trained, empowered, and involved in problem solving and ongoing improvements?"
              name={['companyLean', 'trained_teams']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.trained_teams}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Do employees appear committed to continuous improvement?"
              name={['companyLean', 'commited_employes']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.commited_employes}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Is a timetable posted for equipment preventive maintenance and continuous improvement of tools and processes?"
              name={['companyLean', 'timetable']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.timetable}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Is there an effective project management process, with cost and timing goals, for new product start-ups?"
              name={['companyLean', 'project_managment']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.project_managment}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Is a supplier certification process--with measures for quality, delivery, and cost performance--displayed?"
              name={['companyLean', 'supplier_certification']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.supplier_certification}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Have key product characteristics been identified and fail-safe methods used to forestall propagation of defects?"
              name={['companyLean', 'product_characteristics']}
              valuePropName={'checked'}
              initialValue={!!leanAnswers.product_characteristics}
            >
              <Switch />
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    );
  } else {
    return null;
  }
};

export default EditCompanyLean;
