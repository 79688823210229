import React, { useContext, useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import moment from 'moment';

import CreateQuickscan from './CreateQuickscan';
import { AdminQuickscansContext } from '../../contexts/AdminQuickscansContext';
import { EditOutlined } from '@ant-design/icons';
import EditQuickscan from './EditQuickscan';
import EditQuickscanStatus from './EditQuickscanStatus';
import EditQuickscanPaid from './EditQuickscanPaid';
import columnSearch from '../column-search/CustomColumnSearch';

const StyledIcon = styled.span`
  cursor: pointer;
`;

const QuickscanList = () => {
  const { quickscans, pages, loading, getQuickscans } = useContext(
    AdminQuickscansContext
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [selectedQuickscan, setSelectedQuickscan] = useState({});
  const [selectedQuickscanOrder, setSelectedQuickscanOrder] = useState({});
  const [transformedQuickscans, setTransformedQuickscans] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (quickscans && quickscans.length > 0) {
      setTransformedQuickscans(quickscans);
    }
  }, [quickscans]);

  useEffect(() => {
    if (Object.entries(selectedQuickscanOrder).length === 0) {
      getQuickscans(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuickscanOrder]);

  const handlePage = (e) => {
    setPage(e);
    getQuickscans(e);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('id')
    },
    {
      title: 'Supplier',
      dataIndex: ['Company', 'name'],
      sorter: (a, b) => a.Company.name.localeCompare(b.Company.name),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Client',
      dataIndex: 'client_email',
      sorter: (a, b) => a.client_email?.localeCompare(b.client_email),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('status'),
      render: (quickscan) => {
        return (
          <span
            onClick={() => {
              setModalVisible(true);
              setModal('quick-scan-status');
              setSelectedQuickscanOrder(quickscan);
            }}
            style={{ cursor: 'pointer' }}
          >
            {quickscan.status === 'open' ? 'open' : 'complete'}
          </span>
        );
      }
    },
    {
      title: 'Paid',
      sorter: (a, b) => a.paid - b.paid,
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('paid'),
      render: (quickscan) => {
        return (
          <span
            onClick={() => {
              setModalVisible(true);
              setModal('quick-scan-paid');
              setSelectedQuickscanOrder(quickscan);
            }}
            style={{ cursor: 'pointer' }}
          >
            {quickscan.paid ? 'yes' : 'no'}
          </span>
        );
      }
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('created_at'),
      render: (created_at) => {
        return <span>{moment(created_at).format('YYYY-MM-DD HH:mm:ss')}</span>;
      }
    },
    {
      title: 'Action',
      render: (quickscan) => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('admin-quick-scan');
              setSelectedQuickscan(quickscan);
            }}
          >
            <EditOutlined />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <Table
        columns={columns}
        rowKey={(quickscan) => quickscan.id}
        dataSource={transformedQuickscans}
        pagination={{
          pageSize: 10,
          showSizeChanger: false,
          onChange: handlePage,
          current: page,
          total: pages ? pages.total : 0
        }}
        scroll={{ x: 'max-content' }}
        loading={loading}
      />
      <CreateQuickscan
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <EditQuickscan
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedQuickscan={selectedQuickscan}
        setSelectedQuickscan={setSelectedQuickscan}
      />
      <EditQuickscanStatus
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedQuickscanOrder={selectedQuickscanOrder}
        setSelectedQuickscanOrder={setSelectedQuickscanOrder}
      />
      <EditQuickscanPaid
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedQuickscanOrder={selectedQuickscanOrder}
        setSelectedQuickscanOrder={setSelectedQuickscanOrder}
      />
    </Fragment>
  );
};

export default QuickscanList;
