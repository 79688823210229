import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { CitiesContext } from './CitiesContext';
import { API_ENDPOINT } from '../consts/globals';

export const CommunitiesContext = createContext();

const CommunitiesContextProvider = (props) => {
  const { cities, countries, getCities } = useContext(CitiesContext);
  const { creds } = useContext(AuthContext);
  const [communities, setCommunities] = useState([]);
  const [response, setResponse] = useState({});

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getCommunities = async () => {
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/communities?include=cities,countries`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setCommunities(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const createCommunity = async (community) => {
    setResponse({});

    let image64;

    if (community.image) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(community.image[0].originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }

    try {
      const transformedCommunity = {
        ...community,
        image: await image64
      };

      const response = await aws.fetch(`${API_ENDPOINT}/system/communities`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(transformedCommunity)
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCommunities();
    } catch (error) {
      console.log(error);
    }
  };

  const editCommunity = async (community, id) => {
    setResponse({});

    let image64;

    if (community.image) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(community.image[0].originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }

    try {
      const { image, ...transformedCommunity } = community;

      if (community.image) {
        transformedCommunity.image = await image64;
      }

      const response = await aws.fetch(
        `${API_ENDPOINT}/system/communities/${id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(transformedCommunity)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCommunities();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCommunity = async (id) => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/communities/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCommunities();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCities();
    getCommunities();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CommunitiesContext.Provider
      value={{
        cities,
        countries,
        communities,
        getCommunities,
        createCommunity,
        editCommunity,
        deleteCommunity,
        response,
        setResponse
      }}
    >
      {props.children}
    </CommunitiesContext.Provider>
  );
};

export default CommunitiesContextProvider;
