import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Checkbox } from 'antd';

import { UsersContext } from '../../contexts/UsersContext';
import { defaultFormLayout } from '../../consts/formStyles';
import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';

const CreateUser = (props) => {
  const { createUser, response, setResponse } = useContext(UsersContext);
  const { modalVisible, setModalVisible, modal } = props;
  const [loading, setLoading] = useState(false);
  const [paid, setPaid] = useState(false);
  const [form] = Form.useForm();

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        createUser(values.city);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({
      progress: false
    });
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    if (modal === 'create') {
      if (response.code)
        response.errors = {
          email: response.code
        };

      if (!response.progress && !response.code) {
        closeModal();
      }

      if (!response.progress) {
        setLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'create') {
    return (
      <Modal
        title={`Create new user`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Email"
            name={['user', 'email']}
            {...ajaxErrorFieldValidationProperties(response, 'email')}
            rules={[
              {
                type: 'email',
                message: 'The input is not a valid E-mail address!'
              },
              {
                required: true,
                message: 'Please input an E-mail address!'
              }
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Street"
            name={['user', 'street']}
            {...ajaxErrorFieldValidationProperties(response, 'street')}
          >
            <Input placeholder="Street" />
          </Form.Item>
          <Form.Item
            label="Zip code"
            name={['user', 'zip']}
            {...ajaxErrorFieldValidationProperties(response, 'zip')}
          >
            <Input placeholder="Zip code" />
          </Form.Item>
          <Form.Item
            label="City"
            name={['user', 'city']}
            {...ajaxErrorFieldValidationProperties(response, 'city')}
          >
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item
            label="Country"
            name={['user', 'country']}
            {...ajaxErrorFieldValidationProperties(response, 'country')}
          >
            <Input placeholder="Country" />
          </Form.Item>
          <Form.Item
            label="Business name"
            name={['user', 'business_name']}
            {...ajaxErrorFieldValidationProperties(response, 'business_name')}
          >
            <Input placeholder="Business name" />
          </Form.Item>
          <Form.Item
            label="Business country"
            name={['user', 'business_country']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'business_country'
            )}
          >
            <Input placeholder="Business country" />
          </Form.Item>
          <Form.Item
            label="Business city"
            name={['user', 'business_city']}
            {...ajaxErrorFieldValidationProperties(response, 'business_city')}
          >
            <Input placeholder="Business city" />
          </Form.Item>
          <Form.Item
            label="Business Zip code"
            name={['user', 'business_zip']}
            {...ajaxErrorFieldValidationProperties(response, 'business_zip')}
          >
            <Input placeholder="Business Zip code" />
          </Form.Item>
          <Form.Item
            label="Business address"
            name={['user', 'business_address']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'business_address'
            )}
          >
            <Input placeholder="Business address" />
          </Form.Item>
          <Form.Item
            label="Business VAT"
            name={['user', 'business_vat']}
            {...ajaxErrorFieldValidationProperties(response, 'business_vat')}
          >
            <Input placeholder="Business VAT" />
          </Form.Item>
          <Form.Item
            label="Paid"
            name={['user', 'paid']}
            {...ajaxErrorFieldValidationProperties(response, 'paid')}
          >
            <Checkbox
              onChange={(e) => setPaid(e.target.checked)}
              checked={paid}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default CreateUser;
