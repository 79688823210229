import React, { useContext, useEffect, useState } from 'react';
import { Modal, Spin } from 'antd';
import styled from 'styled-components';

import { NewsContext } from '../../contexts/NewsContext';

const StyledBold = styled.span`
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 22px;
`;

const DeleteNews = (props) => {
  const { deleteNews, response, setResponse } = useContext(NewsContext);
  const {
    modalVisible,
    setModalVisible,
    modal,
    selectedNews,
    setSelectedNews
  } = props;
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
    setSelectedNews({});
  };

  const handleOk = () => {
    setLoading(true);
    deleteNews(selectedNews.id);
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    if (modal === 'delete') {
      if (
        response.requestFor === 'get single' &&
        response.requestInProgress === true
      ) {
        setInitialLoading(true);
      } else if (
        response.requestFor === 'get single' &&
        response.requestInProgress === false
      ) {
        setInitialLoading(false);
      }

      if (
        response.requestFor === 'delete' &&
        response.requestInProgress === false &&
        !response.errors
      ) {
        closeModal();
      }

      if (
        response.requestFor === 'delete' &&
        response.requestInProgress === false
      ) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'delete') {
    return (
      <Modal
        title={`Delete ${selectedNews.title}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Spin spinning={initialLoading}>
          <p>
            This operation will permanently delete{' '}
            <StyledBold>
              {selectedNews.title ? selectedNews.title.toUpperCase() : null}
            </StyledBold>
            !
          </p>
          <p>Are you sure you want to continue?</p>
        </Spin>
      </Modal>
    );
  } else {
    return null;
  }
};

export default DeleteNews;
