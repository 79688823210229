import React, { useContext, useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Table, Button, Divider } from 'antd';

import CreateCompany from './CreateCompany';
import DeleteCompany from './DeleteCompany';
import { CompaniesContext } from '../../contexts/CompaniesContext';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import EditCompanyDetails from './EditCompanyDetails';
import EditCompanyLean from './EditComapnyLean';
import EditCompanyQuickScan from './EditCompanyQuickScan';
import EditCompanySupplierProducts from './EditCompanySupplierProducts';
import columnSearch from '../column-search/CustomColumnSearch';
import EditCompanyOwnership from './EditCompanyOwnership';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const CompaniesList = () => {
  const { companies, loading } = useContext(CompaniesContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [selectedCompany, setSelectedCompany] = useState({});
  const [transformedCompanies, setTransformedCompanies] = useState([]);

  useEffect(() => {
    if (companies && companies.length > 0) {
      const temp = companies.map((company) => {
        const companyName = company.name
          ? company.name
              .split(' ')
              .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
              .join(' ')
          : 'no name';

        return {
          ...company,
          name: companyName,
          country:
            company.Country && company.Country.name
              ? company.Country.name
              : 'No data'
        };
      });

      setTransformedCompanies(temp);
    }
  }, [companies]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('name')
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      ...columnSearch('industry')
    },
    // {
    //   title: 'DUNS number',
    //   dataIndex: 'duns_number'
    // },
    // {
    //   title: 'Tax jurisdiction code',
    //   dataIndex: 'tax_jurisdiction_code'
    // },
    // {
    //   title: 'VAT id',
    //   dataIndex: 'vat_id'
    // },
    // {
    //   title: 'EBIT',
    //   dataIndex: 'ebit'
    // },
    // {
    //   title: 'Website',
    //   dataIndex: 'website'
    // },
    // {
    //   title: 'Short description',
    //   dataIndex: 'short_description'
    // },
    // {
    //   title: 'Address',
    //   dataIndex: 'address'
    // },
    {
      title: 'Country',
      dataIndex: 'country',
      ...columnSearch('country')
    },
    // {
    //   title: 'turnover',
    //   dataIndex: 'turnover'
    // },
    // {
    //   title: 'Type',
    //   dataIndex: 'type'
    // },
    // {
    //   title: 'Owner',
    //   dataIndex: 'owner_id'
    // },
    // {
    //   title: 'Company representative',
    //   dataIndex: 'company_representative'
    // },
    // {
    //   title: 'Reference list',
    //   dataIndex: 'reference_list'
    // },
    // {
    //   title: 'Communication Language',
    //   dataIndex: 'communication_language'
    // },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    // {
    //   title: 'Number of employees',
    //   dataIndex: 'number_of_employees'
    // },
    // {
    //   title: 'Phone',
    //   dataIndex: 'phone'
    // },
    // {
    //   title: 'Postal Code',
    //   dataIndex: 'postcode'
    // },
    // {
    //   title: 'Supplier Type',
    //   dataIndex: 'supplier_type'
    // },
    {
      title: 'Action',
      render: (company) => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('company-details');
              setSelectedCompany(company);
            }}
          >
            1
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('company-lean');
              setSelectedCompany(company);
            }}
          >
            2
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('company-quick-scan');
              setSelectedCompany(company);
            }}
          >
            3
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('company-supplier-product');
              setSelectedCompany(company);
            }}
          >
            4
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('company-ownership');
              setSelectedCompany(company);
            }}
          >
            5
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('delete');
              setSelectedCompany(company);
            }}
          >
            <DeleteOutlined />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        />
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={(company) => company.id}
        dataSource={transformedCompanies}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
        loading={loading}
      />
      <CreateCompany
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <EditCompanyDetails
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCompany={selectedCompany}
      />
      <EditCompanyLean
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCompany={selectedCompany}
      />
      <EditCompanyQuickScan
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCompany={selectedCompany}
      />
      <EditCompanySupplierProducts
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCompany={selectedCompany}
      />
      <EditCompanyOwnership
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCompany={selectedCompany}
      />
      <DeleteCompany
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCompany={selectedCompany}
      />
    </Fragment>
  );
};

export default CompaniesList;
