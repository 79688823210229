import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { API_ENDPOINT } from '../consts/globals';

export const CategorySegmentsContext = createContext();

const CategorySegmentsContextProvider = (props) => {
  const { creds } = useContext(AuthContext);
  const [response, setResponse] = useState({});
  const [categorySegments, setCategorySegments] = useState([]);
  const [segmentFamilies, setSegmentFamilies] = useState([]);
  const [segmentFamilyClasses, setSegmentFamilyClasses] = useState([]);
  const [
    segmentFamilyClassCommodities,
    setSegmentFamilyClassCommodities
  ] = useState([]);
  const [loading, setLoading] = useState(false);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getCategorySegments = async () => {
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/categories-segment`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setCategorySegments(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSegmentFamilies = async (segment) => {
    const includes = 'family';
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/categories-segment/${segment}?include=${includes}`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setSegmentFamilies(responseJson.CategoryFamily);
    } catch (error) {
      console.log(error);
    }
  };

  const getSegmentFamilyClasses = async (segment, family) => {
    const includes = 'class';
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/categories-family/${family}?include=${includes}`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setSegmentFamilyClasses(responseJson.CategoryClass);
    } catch (error) {
      console.log(error);
    }
  };

  const getSelectedSegmentFamilyClassCommodities = async (
    segment,
    family,
    selectedClass
  ) => {
    setLoading(true);

    const includes = 'commodity';
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/categories-class/${selectedClass}?include=${includes}`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setSegmentFamilyClassCommodities(responseJson.CategoryCommodity);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const createCategorySegment = async (segment) => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/categories-segment`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(segment)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategorySegments();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CategorySegmentsContext.Provider
      value={{
        categorySegments,
        segmentFamilies,
        segmentFamilyClasses,
        segmentFamilyClassCommodities,
        getCategorySegments,
        getSegmentFamilies,
        getSegmentFamilyClasses,
        getSelectedSegmentFamilyClassCommodities,
        setSegmentFamilies,
        setSegmentFamilyClasses,
        setSegmentFamilyClassCommodities,
        createCategorySegment,
        response,
        setResponse,
        loading
      }}
    >
      {props.children}
    </CategorySegmentsContext.Provider>
  );
};

export default CategorySegmentsContextProvider;
