export const ajaxErrorFieldValidationProperties = (response, field) => {
  if (!response.errors) return null;
  if (!response.errors[field]) return null;

  return { validateStatus: 'error', help: response.errors[field] };
};

export const formatDate = (date, format = "YYYY-MM-DD") => {
  return date.format(format)
}

export const generateBase64FilesFormat = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })

}

export const generateBase64FilesFormatFromImageUrl = (url, callback, outputFormat) => {
  var img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function(){
    var canvas = document.createElement('CANVAS'),
      ctx = canvas.getContext('2d'), dataURL;
    canvas.height = img.height;
    canvas.width = img.width;
    ctx.drawImage(img, 0, 0);
    dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
    canvas = null;
  };
  img.src = url;
}