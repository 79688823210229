import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Button } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import CreateCategoryCommodity from './CreateCategoryCommodity';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const CategoryClassesList = (props) => {
  const {
    commodities,
    selectedSegment,
    selectedFamily,
    selectedClass,
    getCommodities,
    loading
  } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');

  const columns = [
    {
      title: 'Commodity',
      dataIndex: 'commodity',
      sorter: (a, b) => a.id.localeCompare(b.id),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Commodity name',
      dataIndex: 'commodity_name'
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon={<PlusOutlined />}
          disabled={!(selectedSegment && selectedFamily && selectedClass)}
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create-commodity');
          }}
        />
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={(commodity) => commodity.commodity}
        dataSource={commodities}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
        loading={loading}
      />
      {selectedSegment &&
      selectedFamily &&
      selectedClass &&
      commodities.length > 0 ? (
        <CreateCategoryCommodity
          modalVisible={modalVisible}
          modal={modal}
          segment={selectedSegment}
          family={selectedFamily}
          selectedClass={selectedClass}
          category={commodities[0].category_id}
          getCommodities={getCommodities}
          setModalVisible={setModalVisible}
        />
      ) : null}
    </Fragment>
  );
};

export default CategoryClassesList;
