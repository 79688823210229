export const quickScanModel = {
  formFields: {
    organisation: {
      name: "organisation",
      label: "Organisation - Strategy - Management - Staff Members",
      values: [
        "No clear company goals or orientation; None or inconsistent statements regarding the next steps; Clear organisational obstructions exists.",
        "Superficial company goals or  orientation  Rare, irregular and unsystematic communication; Inconsistent statements regarding the next steps.",
        "General company goals or orientation communicated; Fairly regular and systematic communication, orientated towards goals and progress.",
        "Leadership generally motivating; Effective communication process and statements; No extensive organisational obstacles recognizable.",
        "Motivating leadership towards demanding goals; Clear, consistent communicational statement and strategy; Effective elimination of obstructions and obstacles.",
      ],
    },
    financial: {
      name: "financial",
      label: "Financial assessment",
      values: [
        "No data",
        "No data",
        "No data",
        "No data",
        "No data",
      ],
    },
    system_audit: {
      name: "system_audit",
      label: "System Audit",
      values: [
        "No certification",
        "Internal audit results at hand. Follow-up on action taken verified.",
        "Audit reports of other customers are at hand. Effectiveness of follow-up on action taken verified.",
        "Certification according to wellknown and recognized cartification body. Effectiveness verified.",
        "Certification according to ISO 900x ff or VDA 6.x or IS0 14001.",
      ],
    },
    process: {
      name: "process",
      label: "Process Product Quality",
      values: [
        "No process ongoing QS-control measures verified; No records available regarding extent and degree of ongoing process control; No  specific test / inspection station; Quality awareness not noticeable.",
        "Process ongoing QS-control measures partly verified; Records regarding extent and degree of process not available; Test / inspection stations not optimal; Quality awareness not penetrating; Operator self-inspection only partly applied.",
        "Pprocess ongoing QS-control measures predominantly verified; Records regarding extent and degree of ongoing process control partly exist; Test / inspection stations are suitable; Operator self-inspection inconsistent.",
        "Process ongoing QS-control measures verified; Records regarding extent and degree of process control available; Test / inspection are suitable; Operator self-insection carried out.",
        "Process ongoing QS-control measures verified (SPC,MFU,FMEA); Records in form of quality schedules available and implemented; Test / insection stations for the assurance of product quality optimally equipped; Quality awareness throughout  all ranking levels.",
      ],
    },
    location: {
      name: "location",
      label: "Location - Environment",
      values: [
        "Supply risks extremely high (infrastructure - power supply - forces of nature); Communication channels unsatisfactory (only telephonically).",
        "Supply risk high (infrastructure - power supply  - forces of nature); Communication channels unsatisfactory (only telephonically und fax).",
        "Supply risks improbable (infrastructure - power supply - forces of nature); Communication channels satisfactory (Tel, Fax, Email).",
        "Suply risks improbable (Infrastructure - power supply - forces of nature); Communication channels good/sound (Tel, Fax, Email, EDI).",
        "Supply risks improbable (infrastructure - power supply - forces of nature); Communication channel very good (Tel, Fax, Email, EDI; CAD-interchange).",
      ],
    },
    capacity_planning: {
      name: "capacity_planning",
      label: "Medium-long term capacity planning",
      values: [
        "Medium-/long term capacity planning; Pre-material planning (sub-suppliers) not recognisable.",
        "Medium-/long term capacity planning and/or pre-material planning (sub-supplier) with considerable deficits.",
        "Medium-/long term capacity planning and/or pre-material planning (sub-suppliers) incomplete.",
        "Medium-/long term capacity planning and/or pre-material planning (sub-suppliers) verified.",
        "Medium-/long term capacity planning and/or pre-material planning (sub-suppliers) verified and documented.",
      ],
    },
    product_competence: {
      name: "product_competence",
      label: "Pre-material - and  Product-competence",
      values: [
        "No experience with selection, utilisation and further development of pre-materials; No independent new product and/or further product development (variants).",
        "Limited experience with selection, utilisation and further development of pre-materials; Independent new product and/or further product development (variants) only conditionally possible.",
        "Experience with selection, utilization and further development of the most important pre-materials; Independent new product and/or further product development (variants) possible.",
        "Experience with selection, utilisation and further development of pre-materials; Independent new product and/or further product development (variants) usual.",
        "Experience with selection, utilisation and further development of pre-materials; New products and/or further product development (variants) within the framework of independent initiative; CAE and CAD come into use.",
      ],
    },
    development_competence: {
      name: "development_competence",
      label: "Process development-competence",
      values: [
        "No independent further process development; Unsuitable production processes/methods are not further developed.",
        "Partly independent further process development; Production process / methods are partly further developed.",
        "Independent further process development; As a rule production processes/methods are optimised.",
        "Independent further process development; Production processes/methods are optimised.",
        "Independent further process development; Production processes/methods are permanently optimised (CIP).",
      ],
    },
    productivity: {
      name: "productivity",
      label: "Cost structure Location costs",
      values: [
        "Severe negative cost influence through e.g.: low machine usage rate, operating utilization, overhead costs, expected interest on equity capital, unsuitable shift models, labour costs to be assumed.",
        "Negative cost influences through e.g.: low  machine usage rate, operating utilization, overhead costs, expected interest on equity capital, unsuitable shift models, labour costs to be assumed.",
        "Balanced cost influence through e.g.: machine utilization rate, operating utilization, overhead costs, expected interest on equity capital, suitable shift models, labour costs to be assumed.",
        "Increased competitiveness through cost management e.g.: high machine usage rate, high operating utilisation, low overhead costs, expected interest on equity capital, suitable shift models, labour costs adjusted.",
        "Considerable competitiveness through cost management e.g.: high machine usage rate, high operating utilisation, low overhead costs, expected interest on equity capital, suitable shift models, labour costs adjusted.",
      ],
    },
    control: {
      name: "control",
      label: "Cost recording  - Calculation Control",
      values: [
        "No EDV support system employed; No direct cost allocation on product possible; Areas not organised according to cost centres; No i.e. annual actual - target - comparison for budget planning.",
        "No EDV support System employed; Cost allocation on product based on empirical values; Areas are not  organised according to cost centres; Half-yearly actual - target - comparison for budget planning.",
        "EDV support system employed; Areas organised according to cost centres; Quarterly-actual-target-comparison for budget planning.",
        "EDV support system employed; Areas organised according to cost centres; Actual-target-comparison for budget planning takes place every two months.",
        "EDV support system. A detailed budget plan, a monthly cost centre accounting and an actual-target-comparison exist. For the budget a responsible person is allocated.",
      ],
    },
    s5: {
      name: "s5",
      label: "5S  (Sort, Set in order, Shine, Standardize, Sustain)",
      values: [
        'Production impresses generally as "grubby / messy". Traffic lanes, material storage areas, etc. are not marked. It is not clear, what is and what is not required.',
        "Production does not impress as clean and organized. Traffic lanes, material storage areas are marked, but it is not clear, what is and what is not required. Too few markings for identification.",
        "Clean and well organised work stations, but deviations from required conditions are recognisable; locations for tools and materials are clearly identified; scrap / rework clearly separated.",
        "Clean, well organised work stations; no deviations recognizable from required condition, also not in none-production areas (break-/ rest rooms, coffee corners, etc.)",
        "Formal 5S-improvement activities are planed according to improvement goals and audits are regularly carried out by management (incl. plant-/ production managers)",
      ],
    },
    visual_management: {
      name: "visual_management",
      label: "Visual Management",
      values: [
        "Practically no examples for visual mangement, which exceeds the fundamental forms e.g. marking of passage ways.",
        "No data",
        "Production status in production is always known; All problematical process operation are clearly identified; Stock quantities are not clearly identified, but locations are indicated.",
        "No data",
        "Wide pallets of instruments are implemented over the entire production for visual management review; Status display of production is transparent for every body; Stock (min./max.) and locations are clearly marked.",
      ],
    },
    standardized_work: {
      name: "standardized_work",
      label: "Standardized work",
      values: [
        "No standard work sheets available; Operators (incl. Logistics specialists, maintenance staff, etc.) work according to their empirical values (experience); Work cycles are respectively different according to machine operator and shift.",
        "Standard work sheets available, but are not used/ adhered to i.e. are obsolete; Standard work sheets are not easily accessible to operators.",
        'Standard work sheets available and partly put-up at stations (only "critical" stations); Most operators adhere to instructions.',
        "All stations have clearly visible and current standard worksheets at their disposal; Pperators always comply with instructions.",
        "Standard work sheets are used for operator training and for improvement activities; Operators support further development of standard work sheets.",
      ],
    },
    inventory: {
      name: "inventory",
      label: "Inventory i.e Stock-management",
      values: [
        "Locations and quantities of stock-on-hand (raw material, semi finished, finished) difficult to identify; Stock far away from places of final use. FIFO-Principle not implemented.",
        "For stock-on-hand i.e. warehouse stock  identified areas are available, but these are not clearly marked; Low discipline within material supply. Only isolated areas utilize /use the FIFO-Principle.",
        "Stock-on-hand clearly identified; Formal system to support FIFO-Principle exist, but lead to errors due to lack of discipline and/or weak design.",
        'FIFO-System is "fault proof" and stock-on-hand has clearly defined stations, which are marked close to area of application; Minimum-/maximum inventory is clearly indicated.',
        "FIFO-Principle for total inventory/supply of material; No material is located outside of marked areas; Stock locations can be flexibly moved to new places of final use.",
      ],
    },
    tpm: {
      name: "tpm",
      label: "TPM (Total Productive Maintenance)",
      values: [
        "Frequent machine breakdown without information regarding down time; Machines are dirty and no PM-Plan or check lists are available (PM-preventive maintenance).",
        "For stock-on-hand i.e. warehouse stock  identified areas are available, but these are not clearly marked; Low discipline within material supply. Only isolated areas utilize /use the FIFO-Principle.",
        "Stock-on-hand clearly identified; Formal system to support FIFO-Principle exist, but lead to errors due to lack of discipline and/or weak design.",
        'FIFO-System is "fault proof" and stock-on-hand has clearly defined stations, which are marked close to area of application; Minimum-/maximum inventory is clearly indicated.',
        "FIFO-Principle for total inventory/supply of material; No material is located outside of marked areas; Stock locations can be flexibly moved to new places of final use.",
      ],
    },
    schedule: {
      name: "schedule",
      label: "Level Schedule / Synchronized Processes",
      values: [
        "Production (-planning) of quantities takes place once a month; Speed based on production marginal conditions and not on customers orders.",
        "Production (-planning) of quantities takes place twice a month; Production speed varies according to processes.",
        "Production (-planning) of quantities takes place once a week, the individual processes are fairly synchronous.",
        "Production of important parts daily, production processes are synchronous and production is based on cycle time analysis.",
        "Production is co-ordinated on market/customer demand and based on cycle time.",
      ],
    },
    flow: {
      name: "flow",
      label: "Continuous flow",
      values: [
        "Production areas are isolated, consequently high inventory of semi/unfinished products between operations (i.e. department orientated), products are produced in large lots (leading to long cycle times).",
        "Production areas to a large extent isolated, thus high inventory of unfinished products between operation; Some products are produced by means of 1-piece flow.",
        'Majority (>75%) of processes based on 1-piece flow production; Only "planned" (standardized) unfinished products between processes; Small lot sizes in order to reduce inventory, set-up processes are continuously improved.',
        "Coordinated 1-piece flow production for all process operations, as well as coordinated  turn-off at planned downtimes; Focus on continuous improvements of effectiveness of set-up processes and retooling processes.",
        "Coordinated 1-piece flow production for all process operations; Traditional (large) department processes (i.e. to transfer) integrated in process and “correctly sized”.",
      ],
    },
    cycle: {
      name: "cycle",
      label: "Cycle time",
      values: [
        "Production speed  based not on determined M-Programme, but dependent on current marginal conditions  (operator-prematerial-temperature) process not stable",
        'Standardized, not dynamic production cycle time as rated-target of assembly; no  "real" efforts, this to be co-ordinated on real market demand',
        'Market demand used for "optimal"  cycle time, is however not always adhered to; inefficiencies exist in form of surplus production and low utilization',
        'Market demand is used for the determination of the "optimal" cycle time; actions for implementation: several re-cycles exist; limited space and flexible assembly layout; each work station tight on "optimal" cycle time',
        'Production speed is determined on the basis of "optimal" cycle time, cycle time is calculated on actual market demand',
      ],
    },
    pull: {
      name: "pull",
      label: "PULL - System",
      values: [
        'Production is manually controlled; Material feed and forwarding to down stream processes based on "manual" generated production plan (PUSH); No  communication between processes.',
        "Production is partly PPS controlled; Material feed and forwarding to down stream processes are partly according to (internal) market demand carried out (PUSH-PULL); At random communication between proceesses.",
        'Production extensively PPS controlled; Kanban-Systems exist partly, but "PULL" discipline not always satisfactory (withdrawal of material is not standardized, no inventory control system); Predominantly unsystematic communication between processes.',
        'Production is controlled by "PULL" principle; Daily demands are met by last process operation; Material delivery through standardized material transport routes; Inventory of semi-/ unfinished products limited; System disciplined; Good communication.',
        'Production completely controlled by "PULL"-principle; Daily demands are met by last process operation; Material delivery through standardized material transport routes; Inventory of semi-/ unfinished products strictly limited; System highly disciplined.',
      ],
    },
    deviations: {
      name: "deviations",
      label: "Recognition  variances / deviations / poka yoke",
      values: [
        "No stoppage of process operations; Staff members should detect/identify defects and separate defective parts and remove same from the process. Process however, runs on continuously; Problems are difficulty to be identified and are not noticed outside the area.",
        "No stoppage of process operations; Staff members should detect/identify defects and separate defective parts and remove same from the process, process however, runs on continuously; Problems are detected, evaluated and visualized.",
        "No stoppage of process operations; Focus directed on prevention of defects; Poka-yokes are used for defect identification; In the majority of areas processes are controlled with the assistance of visual display boards; Problems/defectives are detectable.",
        'Visual display boards for the monitoring/control of processes and Pokayokes for automatic stoppage of "turned out of control" processes are applied in 85% in the installation; Deviations are easily detectable for all  and clearly understandable.',
        'Visual display boards for the monitoring  of processes and Pokayokes for automatic stoppage of "out of control" processes, are applied in the entire installation; Deviation are immediately transparent recognizable and understandable.',
      ],
    },
    line_stop_concept: {
      name: "line_stop_concept",
      label: "Line Stop Concept",
      values: [
        "Employees are not allowed to stop the line; No system available for employees to signal discrepancies actively; Only the foreman/ group leader is authorised to stop the line.",
        "Employees are not allowed to stop the line; Process available to signal discrepancies on the part of the employee to the chargehand/foreman, but not efficient and process-sure; Only the foreman/group leader is authorised to stop the line.",
        "Employees are able to stop the line when quality and security problems occur; Andons (beacons) to signal the location of problems are available and explicitly understandable.",
        'When discrepancies occur, employees can stop the line according to determined methods; A "Quick Response Team" is available and has to be at the face after 10 minutes.',
        'When discrepancies occur, employees can stop the line according to determined methods; The "Quick Response Team" has to react during five minutes.',
      ],
    },
    problem_solving: {
      name: "problem_solving",
      label: "Problem solving",
      values: [
        "The majority of the problems are only noticed by staff members and by maintenance staff members, some of these are partly superficially rectified; Problems always occur again and again and lead to low process efficiency (productivity).",
        'Sporadic, inconsistent utilization of problem solving instruments/tools (e.g. 5 X WHY) at occurrence of bigger problems by management and/or production planning; The majority of "problem solution" are consistently always of "quick fixes" through preventive maintenance/repair work, whereby.',
        "Teams are set-up to identify the cause of the problem, whereby working cycles are only applied in case of large problems; Increased focus on identification of problem causes through  5x WHY analysis has resulted in a lower recurring rate.",
        "Teams are set-up for identification of causes of various problems and machine operators are always integrated; Solutions for problem causes are determined through the 5x WHY analysis, which reduces the recurring rate considerably.",
        "The causes of problems and counter measures of all problems are identified through working cycles; Teams are formed for analysis of a wide variety of problems and machine operators can lead the activities; Staff member  involvement is high.",
      ],
    },
    machine_design: {
      name: "machine_design",
      label: "Machine design",
      values: [
        "Each machine/installation must always be operated by 1 staff member, who starts the process operation, monitoring/ surveillances and completes the process operation; No defect prevention (PokaYoke) and automation facilities are installed.",
        "1 staff member can operate more than 1 machine/installation (of the same type series), however, the process operation must be started, must be controlled and process operation must be completed by the one ad the same staff member. No defect prevention and automation facilities are installed.",
        "1 staff member can operate several machine/installation, without constantly controlling/monitoring the process operation; Defect prevention facilities are integrated in the machine design.",
        "Control of machines/installations can be started by pushing 1 button and stops automatically (eventually with auto-eject), without controlling/monitoring machine processes operation.",
        "Machine-/installation design allows loading by staff member, part release takes place automatically, removal possible by means of simple movement.",
      ],
    },
    line_design: {
      name: "line_design",
      label: "Line design",
      values: [
        'Each machine processing or assembly station in the process operation is an isolated "isle"; Staff members cannot move from one station to the next station; Due to space extension communication of staff members is impossible/very difficulty; Obstructed areas.',
        'Each machine processing or assembly station in the process operation is an isolated "isle"; Staff members cannot move from one station to the next; Communication of staff members possible due to narrow space cooperation; Clearly visible areas.',
        "Machine processing or assembly station in the process are partly jointly linked, i.e. staff members can work on more than one station; Area is more clearly visible and easily overviewed from one point.",
        "Layout offers high flexibility at the placing of staff members; Processing machines/production installations are arranged with the pull principle closely to the user (almost no stock at hand).",
        "Activity economy maximized by minimizing  distances and material transport. Leads to  minimum essential production areas (only possible with level 4-5 machine design).",
      ],
    },
    factory_design: {
      name: "factory_design",
      label: "Factory design",
      values: [
        "Job shop production i.e. processing areas are completely separated from each other; No integration of areas possible through low-cost automation; The transport of material/parts through the store, as a rule, requires a significant amount of time.",
        "No data",
        "Factory layout minimizes transport requirements, processes are linked up, layout enables real 1-piece flow.",
        "No data",
        'Continuous 1-piece flow in whole factory with the same production rate, i.e. "synchronized".',
      ],
    },
  },
};
