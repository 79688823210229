import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { defaultFormLayout } from '../../consts/formStyles';

import { CitiesContext } from '../../contexts/CitiesContext';
import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';

const { Option } = Select;

const CreateCity = (props) => {
  const { countries, createCity, response, setResponse } = useContext(
    CitiesContext
  );
  const { modalVisible, setModalVisible, modal } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        createCity(values.city);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  if (modal === 'create') {
    return (
      <Modal
        title={`Add new city`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Name"
            name={['city', 'name']}
            {...ajaxErrorFieldValidationProperties(response, 'name')}
            rules={[{ required: true, message: 'Please input a city name!' }]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Postal code"
            name={['city', 'postal_code']}
            {...ajaxErrorFieldValidationProperties(response, 'postal_code')}
            rules={[
              { required: true, message: 'Please input a postal code!' },
              {
                min: 4,
                message: 'Postal code must be between 4 and 9 characters!'
              },
              {
                max: 9,
                message: 'Postal code must be between 4 and 9 characters!'
              }
            ]}
          >
            <Input placeholder="Postal Code" />
          </Form.Item>
          <Form.Item
            label="Country"
            name={['city', 'country_id']}
            {...ajaxErrorFieldValidationProperties(response, 'country_id')}
            rules={[{ required: true, message: 'Please select a country!' }]}
          >
            <Select placeholder="Country">
              {countries.map((country) => {
                return (
                  <Option key={country.id} value={country.id}>
                    {`${country.name} (${country.iso})`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default CreateCity;
