import React, { Fragment, useContext, useState } from 'react';
import { Col, Divider, Row } from 'antd';

import { CategorySegmentsContext } from '../../contexts/CategorySegmentsContext';
import CategoryCommoditiesList from './CategoryCommoditiesList';
import CategorySegmentsList from './CategorySegmentsList';
import CategoryFamiliesList from './CategoryFamiliesList';
import CategoryClassesList from './CategoryClassesList';

const CreateCategory = () => {
  const {
    categorySegments,
    segmentFamilies,
    segmentFamilyClasses,
    segmentFamilyClassCommodities,
    getCategorySegments,
    getSegmentFamilies,
    getSegmentFamilyClasses,
    getSelectedSegmentFamilyClassCommodities,
    setSegmentFamilies,
    setSegmentFamilyClasses,
    setSegmentFamilyClassCommodities,
    loading
  } = useContext(CategorySegmentsContext);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);

  const segmentSelected = (segment) => {
    setSelectedSegment(segment);
    setSelectedClass(null);
    setSelectedFamily(null);
    setSegmentFamilies([]);
    setSegmentFamilyClasses([]);
    setSegmentFamilyClassCommodities([]);
    getSegmentFamilies(segment);
  };

  const segmentFamilySelected = (family) => {
    setSelectedFamily(family);
    setSelectedClass(null);
    setSegmentFamilyClasses([]);
    setSegmentFamilyClassCommodities([]);
    getSegmentFamilyClasses(selectedSegment, family);
  };

  const segmentFamilyClassSelected = (selectedClass) => {
    setSelectedClass(selectedClass);
    setSegmentFamilyClassCommodities([]);
    getSelectedSegmentFamilyClassCommodities(
      selectedSegment,
      selectedFamily,
      selectedClass
    );
  };

  return (
    <Fragment>
      <Row justify="space-between">
        <Col span={6}>
          <CategorySegmentsList
            justifyContent="start"
            segments={categorySegments}
            segmentSelected={segmentSelected}
            getSegments={getCategorySegments}
            enableCreate={true}
          />
        </Col>
        <Col span={6}>
          <CategoryFamiliesList
            justifyContent="center"
            families={segmentFamilies}
            familySelected={segmentFamilySelected}
            selectedSegment={selectedSegment}
            getFamilies={getSegmentFamilies}
            enableCreate={true}
          />
        </Col>
        <Col span={6}>
          <CategoryClassesList
            justifyContent="end"
            classes={segmentFamilyClasses}
            classSelected={segmentFamilyClassSelected}
            selectedSegment={selectedSegment}
            selectedFamily={selectedFamily}
            getClasses={getSegmentFamilyClasses}
            enableCreate={true}
          />
        </Col>
      </Row>
      <Divider orientation="left">Commodities</Divider>
      <CategoryCommoditiesList
        commodities={segmentFamilyClassCommodities}
        selectedSegment={selectedSegment}
        selectedFamily={selectedFamily}
        selectedClass={selectedClass}
        getCommodities={getSelectedSegmentFamilyClassCommodities}
        loading={loading}
      />
    </Fragment>
  );
};

export default CreateCategory;
