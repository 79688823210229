import React, { useContext, useEffect, useState } from 'react';
import { Modal, Checkbox } from 'antd';

import { AdminQuickscansContext } from '../../contexts/AdminQuickscansContext';

const EditQuickscanOrder = (props) => {
  const { response, setResponse, editQuickscanOrder } = useContext(
    AdminQuickscansContext
  );

  const {
    modalVisible,
    setModalVisible,
    modal,
    selectedQuickscanOrder,
    setSelectedQuickscanOrder
  } = props;
  const [loading, setLoading] = useState(false);
  const [paid, setPaid] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleOk = () => {
    setLoading(true);
    editQuickscanOrder(
      paid ? { paid: true } : { paid: false },
      selectedQuickscanOrder.id
    )
      .then(() => {
        setSelectedQuickscanOrder({});
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setSelectedQuickscanOrder({ nonEmpty: true });
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    setPaid(selectedQuickscanOrder.paid);
  }, [selectedQuickscanOrder]);

  useEffect(() => {
    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'quick-scan-paid') {
    return (
      <Modal
        title={`Edit quick scan paid ${selectedQuickscanOrder.id}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        width="50%"
      >
        <Checkbox
          checked={paid}
          disabled={loading}
          onChange={() => setPaid((prev) => !prev)}
        >
          Paid
        </Checkbox>
      </Modal>
    );
  } else {
    return null;
  }
};

export default EditQuickscanOrder;
