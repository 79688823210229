import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import { AwsClient } from 'aws4fetch';
import { API_ENDPOINT } from '../consts/globals';
import { CitiesContext } from './CitiesContext';

export const CompaniesContext = createContext();

const CompaniesContextProvider = (props) => {
  const { cities, getCities, countries } = useContext(CitiesContext);
  const { creds, cognitoUser } = useContext(AuthContext);
  const [companies, setCompanies] = useState([]);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getCompanies = async () => {
    setLoading(true);

    const includes = 'city,country,certificates,medias';

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/companies?include=${includes}`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setCompanies(responseJson.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getLeanAnswers = async (companyId) => {
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/company-questions/${companyId}`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      return responseJson;
    } catch (error) {
      console.log(error);
    }
  };

  const getQuickScanAnswers = async (companyId) => {
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/quick-scan/${companyId}`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      return responseJson;
    } catch (error) {
      console.log(error);
    }
  };

  const createCompany = async (company, shouldGetCompanies = true) => {
    setResponse({});

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/stepone`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(company)
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      if (shouldGetCompanies) {
        getCompanies();
      }
      console.log('response', responseJson);

      return responseJson;
    } catch (error) {
      console.log(error);
    }
  };

  const createCompanyLean = async (companyLean) => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/company-questions`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(companyLean)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createCompanyQuickScan = async (companyQuickScan) => {
    setResponse({});

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/quick-scan`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(companyQuickScan)
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editCompany = async (company, id) => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/stepone/${id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(company)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCompanies();
    } catch (error) {
      console.log(error);
    }
  };

  const editCompanyLean = async (companyLean, companyId) => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/company-questions/${companyId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(companyLean)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editCompanyQuickScan = async (companyQuickScan, companyId) => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/quick-scan/${companyId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(companyQuickScan)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editCompanySupplierProducts = async (companySupplierProducts) => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/company-categories`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            'X-User-Sub': cognitoUser.attributes.sub
          },
          body: JSON.stringify(companySupplierProducts)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editCompanyOwnership = async (company, id) => {
    setResponse({});

    const params = {
      company_id: id,
      new_owner: company.owner_id
    };

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/transfer-ownership`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(params)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCompanies();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCompany = async (id) => {
    setResponse({});
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/companies/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCompanies();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCities();
    getCompanies();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CompaniesContext.Provider
      value={{
        companies,
        cities,
        countries,
        getCompanies,
        getLeanAnswers,
        getQuickScanAnswers,
        createCompany,
        createCompanyLean,
        createCompanyQuickScan,
        editCompany,
        editCompanyLean,
        editCompanyQuickScan,
        editCompanySupplierProducts,
        editCompanyOwnership,
        deleteCompany,
        response,
        setResponse,
        loading
      }}
    >
      {props.children}
    </CompaniesContext.Provider>
  );
};

export default CompaniesContextProvider;
