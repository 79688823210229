import React, { useContext } from 'react';
import { Layout, Menu } from 'antd';
import styled from 'styled-components';
import logo from '../../img/logo.svg';
import { Link, withRouter } from 'react-router-dom';
import {
  AppstoreOutlined,
  BankOutlined,
  FileDoneOutlined,
  FlagOutlined,
  GlobalOutlined,
  TeamOutlined
} from '@ant-design/icons';

import { APP_NAME } from '../../consts/globals';

import { AdminContext } from '../../contexts/AdminContext';

const StyledSider = styled(Layout.Sider)`
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
`;

const StyledLogo = styled.div`
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;

  img {
    height: 40px;
  }
`;

const Sidebar = (props) => {
  const { userRole } = useContext(AdminContext);
  const { location } = props;

  return (
    <StyledSider width="256">
      <StyledLogo>
        <Link to="/">
          <img src={logo} alt="yvoo" />
        </Link>
      </StyledLogo>

      {(() => {
        switch (userRole) {
          case `${APP_NAME}-admin`:
            return (
              <Menu
                theme="dark"
                selectedKeys={[location.pathname]}
                mode="inline"
              >
                <Menu.Item key="/users">
                  <Link to="/users">
                    <TeamOutlined />
                    <span>Users</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/countries">
                  <Link to="/countries">
                    <FlagOutlined />
                    <span>Countries</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/cities">
                  <Link to="/cities">
                    <GlobalOutlined />
                    <span>Cities</span>
                  </Link>
                </Menu.Item>
                {/*<Menu.Item key="/news">*/}
                {/*  <Link to="/news">*/}
                {/*    <FileTextOutlined />*/}
                {/*    <span>News</span>*/}
                {/*  </Link>*/}
                {/*</Menu.Item>*/}
                {/*<Menu.Item key="/invoices">*/}
                {/*  <Link to="/invoices">*/}
                {/*    <EuroOutlined />*/}
                {/*    <span>Invoices</span>*/}
                {/*  </Link>*/}
                {/*</Menu.Item>*/}
                <Menu.Item key="/legal">
                  <Link to="/legal">
                    <FileDoneOutlined />
                    <span>Legal</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/companies">
                  <Link to="/companies">
                    <BankOutlined />
                    <span>Companies</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/quickscans">
                  <Link to="/quickscans">
                    <BankOutlined />
                    <span>Quickscans</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/custom-search">
                  <Link to="/custom-search">
                    <BankOutlined />
                    <span>Custom searches</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/categories">
                  <Link to="/categories">
                    <AppstoreOutlined />
                    <span>Categories</span>
                  </Link>
                </Menu.Item>
              </Menu>
            );

          case `${APP_NAME}-editor`:
            return (
              <Menu
                theme="dark"
                selectedKeys={[location.pathname]}
                mode="inline"
              >
                <Menu.Item key="/countries">
                  <Link to="/countries">
                    <FlagOutlined />
                    <span>Countries</span>
                  </Link>
                </Menu.Item>
              </Menu>
            );

          default:
            return (
              <Menu
                theme="dark"
                selectedKeys={[location.pathname]}
                mode="inline"
              >
                <Menu.Item key="/countries">
                  <Link to="/countries">
                    <FlagOutlined />
                    <span>Countries</span>
                  </Link>
                </Menu.Item>
              </Menu>
            );
        }
      })()}
    </StyledSider>
  );
};

export default withRouter(Sidebar);
