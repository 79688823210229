import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Select } from 'antd';

import { CitiesContext } from '../../contexts/CitiesContext';
import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';
import { defaultFormLayout } from '../../consts/formStyles';

const { Option } = Select;

const EditCity = (props) => {
  const { countries, editCity, response, setResponse } = useContext(
    CitiesContext
  );
  const { modalVisible, setModalVisible, modal, selectedCity } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        editCity(values.city, selectedCity.id);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    //There is a some sort of race condition problem going on with initialValues and resetFields() in antd4
    //I have to use this use effect so I can reset form values to new values when form loads. Otherwise form will retain old data.
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  useEffect(() => {
    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'edit') {
    return (
      <Modal
        title={`Edit ${selectedCity.name}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Name"
            name={['city', 'name']}
            {...ajaxErrorFieldValidationProperties(response, 'name')}
            rules={[{ required: true, message: 'Please input a city name!' }]}
            initialValue={selectedCity.name}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item
            label="Postal code"
            name={['city', 'postal_code']}
            {...ajaxErrorFieldValidationProperties(response, 'postal_code')}
            rules={[
              { required: true, message: 'Please input a postal code!' },
              {
                min: 4,
                message: 'Postal code must be between 4 and 9 characters!'
              },
              {
                max: 9,
                message: 'Postal code must be between 4 and 9 characters!'
              }
            ]}
            initialValue={selectedCity.postal_code}
          >
            <Input placeholder="Postal code" maxLength={9} />
          </Form.Item>

          <Form.Item
            label="Country"
            name={['city', 'country_id']}
            {...ajaxErrorFieldValidationProperties(response, 'country_id')}
            rules={[{ required: true, message: 'Please select a country!' }]}
            initialValue={selectedCity.country_id}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Country"
            >
              {countries.map((country) => {
                return (
                  <Option key={country.id} value={country.id}>
                    {`${country.name} (${country.iso})`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default EditCity;
