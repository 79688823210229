import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Select, Spin } from 'antd';

import { UsersContext } from '../../contexts/UsersContext';
import { CloseCircleOutlined } from '@ant-design/icons';
import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';
import { defaultFormLayout } from '../../consts/formStyles';

const { Option } = Select;

const ChangeUserGroup = (props) => {
  const {
    groups,
    userGroups,
    changeUserGroup,
    removeUserGroup,
    response,
    setResponse
  } = useContext(UsersContext);
  const { modalVisible, setModalVisible, modal, selectedUser } = props;
  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [form] = Form.useForm();

  const sortedGroups = userGroups.sort((a, b) => {
    return a.Precedence - b.Precedence;
  });

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        changeUserGroup({ ...selectedUser, ...values.user });
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({
      progress: false
    });
    setLoading(false);
    closeModal();
  };

  const handleRemove = (user, group) => {
    removeUserGroup(user, group);
  };

  useEffect(() => {
    //There is a some sort of race condition problem going on with initialValues and resetFields() in antd4
    //I have to use this use effect so I can reset form values to new values when form loads. Otherwise form will retain old data.
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  useEffect(() => {
    if (modal === 'group') {
      if (response.code) {
        response.errors = {
          group: response.code
        };
      }

      if (
        response.requestFor === 'remove group' &&
        response.requestInProgress === true
      ) {
        setRemoveLoading(true);
      } else if (
        response.requestFor === 'remove group' &&
        response.requestInProgress === false
      ) {
        setRemoveLoading(false);
      }

      if (
        response.requestFor === 'change group' &&
        response.requestInProgress === false &&
        !response.code
      ) {
        closeModal();
      }

      if (
        response.requestFor === 'change group' &&
        response.requestInProgress === false
      ) {
        setLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'group') {
    return (
      <Modal
        title={`Change ${selectedUser.username} groups`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Spin spinning={removeLoading}>
          <p>Current groups:</p>
          <ul>
            {sortedGroups.map((group, index) => {
              return (
                <li key={group.GroupName}>
                  {group.GroupName.toUpperCase()}{' '}
                  <CloseCircleOutlined
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleRemove(selectedUser, group.GroupName);
                    }}
                  />
                </li>
              );
            })}
          </ul>

          <p>Add user to:</p>
          <Form {...defaultFormLayout} form={form}>
            <Form.Item
              name={['user', 'group']}
              {...ajaxErrorFieldValidationProperties(response, 'country_id')}
              rules={[{ required: true, message: 'Please select a country!' }]}
              initialValue={userGroups[0] ? userGroups[0].GroupName : null}
            >
              <Select
                name="group"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Group"
              >
                {groups.map((group) => {
                  return (
                    <Option key={group.GroupName} value={group.GroupName}>
                      {group.GroupName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  } else {
    return null;
  }
};

export default ChangeUserGroup;
