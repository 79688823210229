import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input } from 'antd';
import { defaultFormLayout } from '../../consts/formStyles';

import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';
import { CategoryFamiliesContext } from '../../contexts/CategoryFamiliesContext';

const CreateCategoryFamily = (props) => {
  const { createCategoryFamily, response, setResponse } = useContext(
    CategoryFamiliesContext
  );
  const {
    modalVisible,
    setModalVisible,
    modal,
    segment,
    category,
    getFamilies
  } = props;

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        values.family.segment_id = segment;
        values.family.category_id = category;
        createCategoryFamily(values.family);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    if (response.family || response.family_name) {
      getFamilies(segment);
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  if (modal === 'create-family') {
    return (
      <Modal
        title={`Add new family`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Family"
            name={['family', 'family']}
            {...ajaxErrorFieldValidationProperties(response, 'family')}
            rules={[{ required: true, message: 'Please input a family!' }]}
          >
            <Input placeholder="Family" />
          </Form.Item>
          <Form.Item
            label="Name"
            name={['family', 'family_name']}
            {...ajaxErrorFieldValidationProperties(response, 'family_name')}
            rules={[{ required: true, message: 'Please input a family name!' }]}
          >
            <Input placeholder="Name" />
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default CreateCategoryFamily;
