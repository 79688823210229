import React, { useContext, useState } from 'react';
import { Col, Divider, Form, Modal, Row, Select } from 'antd';

import { CategorySegmentsContext } from '../../contexts/CategorySegmentsContext';

import CategorySegmentsList from '../categories/CategorySegmentsList';
import CategoryFamiliesList from '../categories/CategoryFamiliesList';
import CategoryClassesList from '../categories/CategoryClassesList';
import { CompaniesContext } from '../../contexts/CompaniesContext';

const { Option } = Select;

const EditCompanySupplierProducts = (props) => {
  const {
    categorySegments,
    segmentFamilies,
    segmentFamilyClasses,
    segmentFamilyClassCommodities,
    getCategorySegments,
    getSegmentFamilies,
    getSegmentFamilyClasses,
    getSelectedSegmentFamilyClassCommodities,
    setSegmentFamilies,
    setSegmentFamilyClasses,
    setSegmentFamilyClassCommodities
  } = useContext(CategorySegmentsContext);

  const { editCompanySupplierProducts } = useContext(CompaniesContext);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const { modalVisible, setModalVisible, modal, selectedCompany } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const segmentSelected = (segment) => {
    setSelectedSegment(segment);
    setSelectedFamily(null);
    setSegmentFamilies([]);
    setSegmentFamilyClasses([]);
    setSegmentFamilyClassCommodities([]);
    getSegmentFamilies(segment);
  };

  const segmentFamilySelected = (family) => {
    setSelectedFamily(family);
    setSegmentFamilyClasses([]);
    setSegmentFamilyClassCommodities([]);
    getSegmentFamilyClasses(selectedSegment, family);
  };

  const segmentFamilyClassSelected = (selectedClass) => {
    setSegmentFamilyClassCommodities([]);
    getSelectedSegmentFamilyClassCommodities(
      selectedSegment,
      selectedFamily,
      selectedClass
    );
  };

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        let transformedCategoryValues = values.companySupplierProducts.map(
          (categoryId) => {
            return { category_id: categoryId };
          }
        );
        let transformedValues = {
          categories: transformedCategoryValues,
          company_id: selectedCompany.id
        };

        editCompanySupplierProducts(transformedValues);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    // setResponse({});
    setLoading(false);
    closeModal();
  };
  if (modal === 'company-supplier-product') {
    return (
      <Modal
        title={`Add ${selectedCompany.name} commodities`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Row>
          <Col span={24}>
            <Divider orientation="left">Segment</Divider>
            <CategorySegmentsList
              segments={categorySegments}
              segmentSelected={segmentSelected}
              getSegments={getCategorySegments}
              enableCreate={false}
              width="100%"
            />
          </Col>
          <Col span={24}>
            <Divider orientation="left">Family</Divider>
            <CategoryFamiliesList
              families={segmentFamilies}
              familySelected={segmentFamilySelected}
              selectedSegment={selectedSegment}
              getFamilies={getSegmentFamilies}
              enableCreate={false}
              width="100%"
            />
          </Col>
          <Col span={24}>
            <Divider orientation="left">Class</Divider>
            <CategoryClassesList
              classes={segmentFamilyClasses}
              classSelected={segmentFamilyClassSelected}
              selectedSegment={selectedSegment}
              selectedFamily={selectedFamily}
              getClasses={getSegmentFamilyClasses}
              enableCreate={false}
              width="100%"
            />
          </Col>
        </Row>
        <Divider orientation="left">Commodities</Divider>
        {segmentFamilyClassCommodities.length !== 0 ? (
          <Form form={form}>
            <Form.Item name={['companySupplierProducts']}>
              <Select mode="multiple" placeholder="Commodities">
                {segmentFamilyClassCommodities.map((commodity, index) => {
                  return (
                    <Option
                      key={`commodity-${commodity.commodity}`}
                      value={commodity.category_id}
                    >
                      {commodity.commodity_name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        ) : "Please select segment/family/class to choose commodities to add"}
      </Modal>
    );
  } else {
    return null;
  }
};

export default EditCompanySupplierProducts;
