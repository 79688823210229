import React, { useState } from 'react';
import { Button, Row, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import CreateCategoryClass from './CreateCategoryClass';

const { Option } = Select;

const StyledButton = styled(Button)`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const CategoryClassesList = (props) => {
  const {
    justifyContent,
    classes,
    selectedSegment,
    selectedFamily,
    classSelected,
    getClasses,
    enableCreate,
    width
  } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');

  return (
    <Row justify={justifyContent}>
      <Select
        showSearch
        disabled={!(selectedSegment && selectedFamily && classes.length > 0)}
        style={{ width: width ?? 200 }}
        placeholder="Select a class"
        optionFilterProp="children"
        onChange={classSelected}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {classes.map((selectedClass) => {
          return (
            <Option value={selectedClass.class} key={selectedClass.class}>
              {selectedClass.class_name}
            </Option>
          );
        })}
      </Select>
      {enableCreate ? (
        <StyledButton
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          disabled={!(selectedSegment && selectedFamily)}
          onClick={() => {
            setModalVisible(true);
            setModal('create-class');
          }}
        />
      ) : null}

      {selectedSegment &&
      selectedFamily &&
      classes.length > 0 &&
      enableCreate ? (
        <CreateCategoryClass
          modalVisible={modalVisible}
          modal={modal}
          segment={selectedSegment}
          family={selectedFamily}
          category={classes[0].category_id}
          getClasses={getClasses}
          setModalVisible={setModalVisible}
        />
      ) : null}
    </Row>
  );
};

export default CategoryClassesList;
