import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input } from 'antd';
import { defaultFormLayout } from '../../consts/formStyles';

import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';
import { CategoryCommoditiesContext } from '../../contexts/CategoryCommoditiesContext';

const CreateCategoryCommodity = (props) => {
  const { createCategoryCommodity, response, setResponse } = useContext(
    CategoryCommoditiesContext
  );
  const {
    modalVisible,
    setModalVisible,
    modal,
    segment,
    family,
    selectedClass,
    category,
    getCommodities
  } = props;

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        values.commodity.segment_id = segment;
        values.commodity.family_id = family;
        values.commodity.class_id = selectedClass;
        values.commodity.category_id = category;
        createCategoryCommodity(values.commodity);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    if (response.commodity) {
      getCommodities(segment, family, selectedClass);
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  if (modal === 'create-commodity') {
    return (
      <Modal
        title={`Add new commodity`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Commodity"
            name={['commodity', 'commodity']}
            {...ajaxErrorFieldValidationProperties(response, 'commodity')}
            rules={[{ required: true, message: 'Please input a commodity!' }]}
          >
            <Input placeholder="Commodity" />
          </Form.Item>
          <Form.Item
            label="Name"
            name={['commodity', 'commodity_name']}
            {...ajaxErrorFieldValidationProperties(response, 'commodity_name')}
            rules={[
              { required: true, message: 'Please input a commodity name!' }
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default CreateCategoryCommodity;
