import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import styled from 'styled-components';

import { AuthContext } from '../../contexts/AuthContext';

const SpinWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrivateRoute = ({ children, ...rest }) => {
  const { loading, auth } = useContext(AuthContext);
  return (
    <div>
      <Route
        {...rest}
        render={({ location }) => {
          if (auth && !loading) {
            return children;
          } else {
            if (loading) {
              return (
                <SpinWrapper>
                  <Spin size="large" />
                </SpinWrapper>
              );
            } else
              return (
                <Redirect
                  to={{
                    pathname: '/'
                  }}
                />
              );
          }
        }}
      />
    </div>
  );
};

export default PrivateRoute;
