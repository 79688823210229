import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Button, Divider } from 'antd';
import moment from 'moment';

import { NewsContext } from '../../contexts/NewsContext';
import SingleNews from './SingleNews';
import CreateNews from './CreateNews';
import EditNews from './EditNews';
import DeleteNews from './DeleteNews';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const StyledSpan = styled.span`
  color: #1890ff;
  cursor: pointer;
`;

const NewsList = () => {
  const { news, getSingleNews, selectedNews, setSelectedNews } = useContext(
    NewsContext
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [drawer, setDrawer] = useState(false);

  const transformedNews = news;

  const columns = [
    {
      title: 'Title',
      key: 'title',
      sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (news) => (
        <StyledSpan
          onClick={() => {
            getSingleNews(news.id);
            setDrawer(true);
          }}
        >
          {news.title}
        </StyledSpan>
      )
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Action',
      render: (news) => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('edit');
              getSingleNews(news.id);
            }}
          >
            <EditOutlined />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('delete');
              getSingleNews(news.id);
            }}
          >
            <DeleteOutlined />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        />
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={(news) => news.id}
        dataSource={transformedNews}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
      <CreateNews
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <SingleNews
        drawer={drawer}
        setDrawer={setDrawer}
        selectedNews={selectedNews}
        setSelectedNews={setSelectedNews}
      />
      <EditNews
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedNews={selectedNews}
        setSelectedNews={setSelectedNews}
      />
      <DeleteNews
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedNews={selectedNews}
        setSelectedNews={setSelectedNews}
      />
    </Fragment>
  );
};

export default NewsList;
